<template>
    <div class="mt-n6 div-reset-password">
        <div class="pt-10 d-block">
            <label
                class="yellow--text text--darken-3 font-weight-bold text-h6 pb-2"
                style="border-bottom: 1px solid;"
            >
                ตั้งค่ารหัสผ่านใหม่
            </label>
        </div>
        <v-container class="pt-15 pb-16" v-resize="onResize" :style="{width: windowSize.w + 'px'}">
            <v-form v-model="isValid" ref="form" class="form-reset-password" @submit.prevent="sendResetPassword">
                <div class="d-block mx-8 pt-5">
                    <v-text-field
                        disabled
                        color="yellow darken-3"
                        dark
                        label="อีเมล"
                        v-model="form.username"
                    >
                    </v-text-field>
                </div>
                <div class="d-block mx-8">
                    <v-text-field
                        color="yellow darken-3"
                        dark
                        label="รหัสผ่านใหม่"
                        v-model="form.newPassword"
                        :rules="passwordRules"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                    >
                    </v-text-field>
                    <v-text-field
                        color="yellow darken-3"
                        dark
                        label="ยืนยันรหัสผ่าน"
                        v-model="form.confirmPassword"
                        :rules="[v => v == form.newPassword || 'รหัสผ่านไม่ตรงกัน']"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                    >
                    </v-text-field>
                </div>
                <v-btn rounded color="yellow darken-3" class="px-10 mt-4" @click="sendResetPassword">
                    ตั้งรหัสผ่านใหม่
                </v-btn>
            </v-form>
        </v-container>
        <dialog-error
            ref="dialogValidateFail"
            :topic="'ข้อมูลไม่ถูกต้อง'"
            :text="'กรุณากรอกข้อมูลใหม่อีกครั้ง'"
        />
        <dialog-error
            ref="dialogError"
            :topic="'Error'"
            :text="errorMessage"
        />
        <dialog-success
            ref="dialogSuccess"
            :text="'เปลี่ยนรหัสผ่านสำเร็จ โปรดเข้าสู่ระบบใหม่'"
            :redirectTo="{ name: 'Login' }"
        />
    </div>
</template>

<script>
import { emailRules, passwordRules } from '../utils/validation-rule';
import DialogError from '../components/DialogError.vue';
import DialogSuccess from '../components/DialogSuccess.vue';
import { sendResetPassword } from '../models/customer';

export default {
    data: () => ({
        height: 0,
        width: 0,
        isValid: false,
        form: {
            username: '',
            newPassword: '',
            confirmPassword: ''
        },
        windowSize: {},
        emailRules,
        passwordRules,
        errorMessage: '',
        showPassword: false
    }),
    methods: {
        onResize(){
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },
        async sendResetPassword(){
            // get profile and update
            this.$refs.form.validate();
            if(!this.isValid){
                this.$refs.dialogValidateFail.show();
            }else{
                const loader = this.$loading.show();
                const token = this.$route.query.token;
                try{
                    await sendResetPassword(token, this.form);
                    localStorage.clear();
                    this.$refs.dialogSuccess.show();
                }catch(error){
                    this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                    this.$refs.dialogError.show();
                }finally{
                    loader.hide();
                }
            }
        }
    },
    computed: {
    },
    async mounted(){
        this.form.username = this.$route.query.username;
    },
    watch: {
    },
    components: {
        DialogError,
        DialogSuccess
    }
};
</script>

<style scoped>
    .div-reset-password{
        text-align: center;
    }
    .form-reset-password{
        background-color: #0F0E0D;
        border-radius: 8px;
        padding-bottom: 32px;
    }
    
</style>

<style>
    .v-radio .v-icon {
        background-image: radial-gradient(#cccccc 40%, transparent 10%);
    }
</style>