/* eslint-disable no-unused-vars */

/**
 * 
 * @param {*} form 
 * response Promise request
 */
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';

const registerPerson = (form) => {
    const formData = new FormData();
    formData.append('type', 'person');
    formData.append('name', form.name);
    formData.append('mobile', form.mobile);
    formData.append('email', form.email.trim().toLowerCase());
    formData.append('password', form.password);
    formData.append('confirmPassword', form.confirmPassword);
    formData.append('address_address1', form.address.address1);
    formData.append('address_address2', form.address.address2);
    formData.append('address_lat', form.address.location?.lat || '');
    formData.append('address_lng', form.address.location?.lng || '');
    formData.append('fileId', form.fileId);
    formData.append('idTax', form.idTax);
    return axios.post(process.env.VUE_APP_BASE_URL + '/users/customer/register', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

const registerCorp = (form) => {
    const formData = new FormData();
    formData.append('type', 'corporation');
    formData.append('companyName', form.companyName);
    formData.append('name', form.name);
    formData.append('mobile', form.mobile);
    formData.append('email', form.email);
    formData.append('password', form.password);
    formData.append('confirmPassword', form.confirmPassword);
    formData.append('address_address1', form.address.address1);
    formData.append('address_address2', form.address.address2);
    formData.append('address_lat', form.address.location?.lat || '');
    formData.append('address_lng', form.address.location?.lng || '');
    formData.append('fileKP', form.fileKP);
    formData.append('fileCert', form.fileCert);
    formData.append('idTax', form.idTax);
    return axios.post(process.env.VUE_APP_BASE_URL + '/users/customer/register', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

const getCurrentUser = () => {
    const jwt = localStorage.getItem('jwt');
    return jwt ? jsonwebtoken.decode(jwt) : null;
};

const getMyProfile = () => {
    return axios.get(process.env.VUE_APP_BASE_URL + '/users/customers/profile');
};

const updateMyProfile = (form) => {
    const formData = new FormData();
    formData.append('name', form.name);
    formData.append('mobile', form.mobile);
    formData.append('address_address1', form.address.address1);
    formData.append('address_address2', form.address.address2);
    formData.append('address_lat', form.address.lat);
    formData.append('address_lng', form.address.lng);
    formData.append('idTax', form.idTax);
    return axios.patch(process.env.VUE_APP_BASE_URL + '/users/customers', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

const sendForgotPassword = (email) => {
    return axios.post(process.env.VUE_APP_BASE_URL + '/customers/forgot-password/send/' + email);
};

const sendResetPassword = (token, form) => {
    return axios.post(process.env.VUE_APP_BASE_URL + '/customers/forgot-password/new?token=' + token, form);
};

const getMyNotification = () => {
    return axios.get(process.env.VUE_APP_BASE_URL + '/jobs/customers/my-bell');
};

const checkInMyBell = (id) => {
    return axios.post(process.env.VUE_APP_BASE_URL + `/jobs/customers/my-bell/${id}/check-in`);
};

export {
    registerCorp,
    registerPerson,
    getCurrentUser,
    getMyProfile,
    updateMyProfile,
    sendForgotPassword,
    sendResetPassword,
    getMyNotification,
    checkInMyBell
};